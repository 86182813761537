import Link from "next/link";
import { WaitlistUsers } from ".";
import { FaCheck } from "react-icons/fa";

const pointsToHighlight = [
  "Save Time: Summarize Long Reads",
  "Boost Productivity: Stress Less, Do More",
  "Efficiency Saves Money: Time is Currency",
];

const DemoSection = () => {
  return (
    <div
      className="w-[100%] lg:w-[50%] p-5 lg:pr-5 flex items-center justify-start"
      dangerouslySetInnerHTML={{
        __html: `<div style="position: relative; padding-bottom: calc(62.79296875% + 41px); height: 0; width: 100%;">
                <iframe
                src="https://demo.arcade.software/9vd47KAtoq5mIU77E37a?embed&show_copy_link=true"
                title="See What Notsly Can Do For you!"
                frameborder="0"
                loading="lazy"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                allow="clipboard-write"
                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;color-scheme: light;"
                ></iframe>
                </div>`,
      }}
    />
  );
};

const HeroContentSection = () => {
  return (
    // <div className="text-balance flex flex-col w-[100%] p-5 gap-7 lg:w-[50%] max-lg:text-center max-lg:items-center max-lg:mt-40">
    <div className="text-balance flex flex-col w-[100%] p-5 gap-7 items-center text-center justify-center">
      <h1 className="text-3xl lg:text-5xl font-bold leading-tight">
        Get more done in <br /> less time,{" "}
        <span className="inline-flex animate-text-gradient bg-gradient-to-r from-primary-800 via-primary-600 to-primary-900 bg-[200%_auto] bg-clip-text text-transparent">
          everytime.
        </span>
      </h1>
      <h2 className="text-sm md:text-xl w-[100%] lg:w-[70%]">
        Notsly is your{" "}
        <span className="text-primary-900 font-bold text-normal lg:text-2xl italic">
          AI-powered
        </span>{" "}
        productivity sidekick, combining{" "}
        <span className="text-primary-900 font-bold text-normal lg:text-2xl italic">
          summarization
        </span>
        , meeting transcription, &{" "}
        <span className="text-primary-900 font-bold text-normal lg:text-2xl italic">
          calendar integration
        </span>{" "}
        into one easy-to-use tool.
      </h2>
      <div className="flex">
        <div className="flex flex-col max-lg:text-sm">
          {pointsToHighlight.map((point) => (
            <span key={point} className="flex gap-2 items-center">
              <FaCheck className="text-primary-800" />
              {point}
            </span>
          ))}
        </div>
      </div>
      <Link
        href={"/auth"}
        className="px-5 py-2 rounded
  hover:text-white hover:bg-primary-800 transition-all
flex gap-3 items-center justify-center bg-primary-900 text-white text-sm font-bold
w-[80%] md:max-w-max"
      >
        Try Our $1 Trial Now
      </Link>
      <WaitlistUsers classes="items-center" />
    </div>
  );
};

const HeroV2 = () => {
  return (
    <>
      <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:44px_54px]">
        <div className="absolute left-0 right-0 top-0 -z-10 m-auto h-[310px] w-[310px] rounded-full bg-primary-400 opacity-20 blur-[100px]"></div>
      </div>
      <div className="w-screen h-screen flex items-center justify-between flex-wrap lg:px-32">
        <HeroContentSection />
        {/* <DemoSection /> */}
      </div>
    </>
  );
};

export default HeroV2;
