import Image from "next/image";
import { FaStar } from "react-icons/fa";

const profiles = [
  {
    profileLink: "https://twitter.com/ahaanpandit",
    picLink: "/ahaanpandit.webp",
  },
  {
    profileLink: "https://twitter.com/SenthilbalajiG",
    picLink: "/SenthilbalajiG.webp",
  },
  {
    profileLink: "https://twitter.com/SanjaySekaren",
    picLink: "/SanjaySekaren.webp",
  },
  {
    profileLink: "https://twitter.com/its_vin",
    picLink: "/its_vin.webp",
  },
  {
    profileLink: "https://twitter.com/_glnarayanan",
    picLink: "/_glnarayanan.webp",
  },
];

const WaitlistUsers = (props: { classes?: string; textClass?: string }) => {
  return (
    <div className={`flex flex-col ${props.classes} max-md:items-center`}>
      <div className="flex gap-5 items-center flex-wrap max-md:justify-center">
        <div className="flex">
          {profiles.map((profile) => (
            <Image
              key={profile.profileLink}
              src={profile.picLink}
              alt={"profile picture of twitter profile"}
              width={45}
              height={45}
              className="rounded-full -ml-2 transition-all ring-4 ring-primary-50"
              priority
            />
          ))}
        </div>
        <div>
          <div className="flex max-md:justify-center gap-1">
            <FaStar color="orange" />
            <FaStar color="orange" />
            <FaStar color="orange" />
            <FaStar color="orange" />
            <FaStar color="orange" />
          </div>
          <p className={`text-xs text-zinc-800 mt-1 ${props.textClass}`}>
            2000+ other Notslers are saving time.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WaitlistUsers;
