import { FaCalendarAlt } from "react-icons/fa";
import { CgArrangeFront } from "react-icons/cg";
import { MdSummarize } from "react-icons/md";
import Link from "next/link";
import Image from "next/image";

const features = [
  {
    title: "Summarize your notes",
    icon: <MdSummarize size="2rem" />,
    description:
      "Notsly structures your notes & highlights the important points.",
  },
  {
    title: "Block your calendar for notes",
    icon: <FaCalendarAlt size="2rem" />,
    description:
      "Notsly blocks time for key tasks by adding them on your calendar.",
  },
  {
    title: "Organize your Notes",
    icon: <CgArrangeFront size="2rem" />,
    description: "Notsly uses its web interface to manage all your notes.",
  },
];

const Features2 = () => {
  const getFeatureContent = () => {
    return (
      <div className="lg:w-[50%] w-[100%] p-3 md:max-lg:text-center md:max-lg:flex md:max-lg:flex-col md:max-lg:items-center">
        <h1 className="font-extrabold text-3xl">
          Manage your notes and tasks in one place
        </h1>
        <h2 className="font-light mt-3">
          Unlock Early Access Deals! Join the waitlist for special offers.
          Elevate your note-taking with Notsly.
        </h2>
        <div className="mt-3">
          {features.map((feature) => {
            return (
              <div
                key={feature.title}
                className="flex justify-start items-center gap-3 mt-3"
              >
                <div className="bg-primary-200/40 text-primary-800 p-3 rounded-lg font-extrabold">
                  {feature.icon}
                </div>
                <div className="text-start">
                  <h1 className="text-lg font-medium">{feature.title}</h1>
                  <p className="font-extralight mt-2 text-sm">
                    {feature.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center p-5">
          <Link
            href={"/auth"}
            className="px-5 py-2 rounded mt-5
            hover:text-white hover:bg-primary-800 transition-all
 flex gap-3 items-center justify-center bg-primary-900 text-white text-sm font-bold
 w-[80%] md:max-w-max"
          >
            Join Now
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div id="features" className="w-[100%] flex flex-col items-center">
      <p className="font-bold mt-20 text-xs opacity-80">FEATURES</p>
      <h1 className="text-2xl md:text-4xl font-extrabold leading-relaxed w-[60%] lg:w-[30%] text-center">
        <span className="font-mono text-primary-900">Notes + AI</span> = Quicker
        Work.
      </h1>
      <div className="p-3 flex flex-wrap items-center justify-center">
        <Image
          src="/hero.svg"
          className="lg:w-[40%] md:w-[60%] hidden md:block"
          alt="features page illustration"
          width={100}
          height={100}
          priority={false}
        />
        {getFeatureContent()}
      </div>
    </div>
  );
};

export default Features2;
