export const proFeatures = [
  "AI Audio Notes (375 Mins/month)",
  "Audio Notes Upto 30 mins Long",
  "AI Text Notes (1M tokens/month**)",
  "Summarize upto 10 YT videos/day",
  "Summarize video upto 1.5 hours long",
  "Unlimited Text Notes",
  "Unlimited Audio Notes, Credits Apply",
  "Calendar Integration",
  "Notion Style Text Editor",
  "PDF Summarizer",
];

export const trialFeatures = [
  "AI Audio Note (30 mins total)",
  "Audio Notes Upto 15 mins Long",
  "AI Text Notes (50k tokens**)",
  "Summarize upto 5 YT videos/day",
  "Summarize video upto 30 mins long",
  "Unlimited Text Notes",
  "Unlimited Audio Notes, Credits Apply",
  "Calendar Integration",
  "Notion Style Text Editor",
  "PDF Summarizer",
];

export const proFeaturesOnly = [
  "Summarize upto 10 videos per day",
  "Summarize video upto 1.5 hours long",
  "1M Tokens Included",
  "Create Audio Note Upto 30 mins Long",
  "375 Audio Note Mins per month",
  "Unlimited Text Notes",
  "Unlimited Audio Notes, Credits Apply",
  "PDF Summarizer",
];
