"use client";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
import { CiCircleCheck } from "react-icons/ci";
import { FaSpinner } from "react-icons/fa";
import clsx from "clsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { waitlistSchema } from "@/schemas/waitlist";
import { z } from "zod";
import Confetti from "react-confetti";
import { WaitlistUsers } from ".";

type WaitlistFormType = z.infer<typeof waitlistSchema>;

export const WaitlistForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WaitlistFormType>({
    resolver: zodResolver(waitlistSchema),
  });
  const [waitlistLoadingState, setWaitlistLoadingState] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [confettiPieces, setConfettiPieces] = useState(200);

  const handleOnSubmitWaitlist = handleSubmit(async (data) => {
    try {
      setWaitlistLoadingState("loading");
      await axios.post("/api/waitlist", { ...data });
      setWaitlistLoadingState("success");
      toggleSuccessModal();
      setConfettiPieces(200);
      setTimeout(() => {
        setConfettiPieces(0);
      }, 1000);
    } catch (err: any) {
      setWaitlistLoadingState("");
    } finally {
      setTimeout(() => {
        setWaitlistLoadingState("");
      }, 3000);
    }
  });

  const toggleSuccessModal = () => setShowSuccessModal(!showSuccessModal);

  return (
    <>
      <dialog
        className={clsx({
          modal: true,
          "modal-open": showSuccessModal,
        })}
      >
        <Confetti
          width={1200}
          height={1200}
          numberOfPieces={confettiPieces}
          gravity={0.3}
        />
        <div className="modal-box">
          <h3 className="font-bold text-lg">Thank you for signing up! 🥳</h3>
          <p className="py-4">
            We will reach out to you once we start onboarding. Keep an eye out
            for our email.👀
          </p>
          <button onClick={toggleSuccessModal} className="btn btn-primary">
            Close
          </button>
        </div>
      </dialog>
      <WaitlistUsers />
      <form
        className="flex flex-col md:flex-row items-center justify-center gap-5 w-[100%]"
        onSubmit={handleOnSubmitWaitlist}
      >
        <div className="flex flex-col w-[80%] md:w-[30%]">
          <input
            placeholder="Your email address"
            className={clsx({
              "p-2 rounded border border-primary-500": true,
              "border-red-500": errors.emailId,
            })}
            id="join-waitlist"
            {...register("emailId")}
            autoFocus
          />
          <span className="text-red-500 md:hidden">
            {errors?.emailId?.message || ""}
          </span>
        </div>
        <button
          className="px-5 py-2 rounded bg-primary-200
              hover:text-white hover:bg-primary-500 transition-all
   flex gap-3 items-center justify-center bg-primary-300/50 text-primary-500 text-sm font-bold
   w-[80%] md:max-w-max"
        >
          Add to Waitlist{" "}
          {waitlistLoadingState === "loading" && (
            <FaSpinner className="animate-spin" />
          )}
          {waitlistLoadingState === "success" && (
            <CiCircleCheck size="1.3rem" />
          )}
        </button>
      </form>
      <span className="text-red-500 -ml-[35%] hidden md:block">
        {errors?.emailId?.message || ""}
      </span>
    </>
  );
};

export default WaitlistForm;
