"use client";
import { useState } from "react";
import { CgClose } from "react-icons/cg";
import { headerLinks } from "./headerContants";
import HeaderActionButton from "./HeaderActionButton";
import Link from "next/link";
import { BiMenu } from "react-icons/bi";

const HeaderMenu = () => {
  const [isMenuShowing, setIsMenuShowing] = useState(false);

  const toggleMenuState = () => setIsMenuShowing(!isMenuShowing);

  if (isMenuShowing) {
    return (
      <div className="fixed z-20 top-0 left-0 w-[100vw] h-[100vh] p-10 bg-white">
        <div className="flex flex-col gap-10">
          <CgClose
            size="1.2rem"
            className="cursor-pointer self-end"
            onClick={toggleMenuState}
          />
          {headerLinks.map((item) => (
            <Link
              key={item.href}
              onClick={toggleMenuState}
              className="text-zinc-500 hover:text-zinc-800"
              href={item.href}
              target={item.target}
            >
              {item.title}
            </Link>
          ))}
          <HeaderActionButton classes={"block lg:hidden"} />
        </div>
      </div>
    );
  }
  return (
    <BiMenu
      size="2rem"
      className="lg:hidden cursor-pointer"
      onClick={toggleMenuState}
    />
  );
};

export default HeaderMenu;
