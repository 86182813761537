import Image from "next/image";

const PoweredBy = () => {
  return (
    <div className="m-10">
      <h1 className="text-center text-2xl m-3">✨ Powered By ✨</h1>
      <div className="w-[100%] flex justify-center gap-10">
        <div
          className="w-[40%] cursor-pointer p-5 hover:bg-primary-100 flex flex-col font-extralight hover:font-bold
         justify-center items-center hover:shadow-xl rounded-xl transition-all hover:scale-110 gap-3"
        >
          <Image
            src="/google.svg"
            alt="google logo"
            width={48}
            height={48}
            priority
          />
          <h1 className="transition-all text-xs text-balance text-center">Gemini Pro</h1>
        </div>
        <div
          className="w-[40%] cursor-pointer p-5 hover:bg-primary-100 flex flex-col font-extralight hover:font-bold
         justify-center items-center hover:shadow-xl rounded-xl transition-all hover:scale-110 gap-3"
        >
          <Image
            src="/openai.svg"
            alt="open ai logo"
            width={48}
            height={48}
            priority
          />
          <h1 className="transition-all text-xs text-balance text-center">Open AI</h1>
        </div>
      </div>
    </div>
  );
};

export default PoweredBy;
