import { FaHeart } from "react-icons/fa6";
import Script from "next/script";

const Testimonials = () => {
  return (
    <>
      <div className="w-[100%] flex items-center flex-col justify-center">
        <p className="font-bold mt-20 text-xs opacity-80">TESTIMONIALS</p>
        <h1 className="text-2xl md:text-4xl font-extrabold leading-relaxed w-[60%] lg:w-[30%] text-center flex justify-center">
          <FaHeart color="red" />
          &nbsp;from the&nbsp;
          <span className="font-mono text-primary-900">Web.</span>
        </h1>
      </div>
      <div
        className="senja-embed w-[90%] ml-[50%] translate-x-[-50%] mt-10"
        data-id="33979aca-5b49-46b9-be34-26ff985b8579"
        data-lazyload="false"
      ></div>
      <Script
        async
        type="text/javascript"
        src="https://static.senja.io/dist/platform.js"
        id="senja-testimonials-notsly"
        strategy="lazyOnload"
      />
    </>
  );
};

export default Testimonials;
