import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  UseDisclosureProps,
} from "@chakra-ui/react";

const BuyCreditsModal = (
  props: {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
  } & UseDisclosureProps
) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent minW="50%">
        <ModalCloseButton />
        <ModalBody className="w-[100%]">
          <div className="flex flex-col items-center justify-center gap-10">
            <h1 className="text-2xl">Daily or Monthly Quota Exceeded!</h1>
            <div className="flex flex-col text-center gap-5">
              <p>
                You are our valued customer and seems like you have hit the
                maximum limit put forth for the feature you are trying. If you
                are interested in this feature very much and want to use more,
                Please write to us at <strong>support@notsly.com</strong>.
              </p>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BuyCreditsModal;
