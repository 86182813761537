import { FaCheck } from "react-icons/fa";
import Link from "next/link";
import _ from "lodash";

interface PricingCard {
  packageName: string;
  features: (string | undefined)[];
  pricingSection: React.ReactNode;
  href: string;
}

const PricingCard = (props: PricingCard) => {
  return (
    <div className="mt-10 text-black flex flex-col justify-between gap-5 min-w-[320px] rounded-xl shadow-2xl transition-all">
      <div className="pl-7 pt-7 pr-7">
        <p className="bg-primary-300/50 rounded-full text-center text-primary-900 px-4 py-1 text-sm font-bold">
          {props.packageName}
        </p>
      </div>
      {props.pricingSection}
      <div>
        <h1 className="text-center font-light ml-5">
          {_.capitalize(props.packageName)} Plan Includes
        </h1>
        <div className="flex flex-col items-start mt-5 bg-primary-100 p-3 max-md:p-1 pl-5 w-[100%] max-w-[380px] gap-2">
          {props.features.map((feature, index) => (
            <p
              className="text-sm font-medium flex items-center gap-6 ml-10"
              key={index}
            >
              {feature && <FaCheck className="text-primary-800" />}
              {feature || <pre>&nbsp;</pre>}
            </p>
          ))}
        </div>
        <p className="text-xs text-slate-500 px-5 text-center text-balance max-w-[380px] my-3">
          * If you reach your limits earlier, you have the option to purchase
          additional credits upon sending us a mail.
        </p>
        <p className="text-xs text-slate-500 px-5 text-center text-balance max-w-[380px] my-3">
          ** Please note that tokens are shared across features wherever prompts
          are triggered.
        </p>
        <div className="flex justify-center m-5">
          <Link
            href={props.href}
            className="px-5 py-2 rounded
            hover:text-white hover:bg-primary-800 transition-all
 flex gap-3 items-center justify-center bg-primary-900 text-white text-sm font-bold
 w-[80%] md:max-w-max"
          >
            Buy Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
