import Image from "next/image";

const featuresCardDetails = [
  {
    title: "Intelligent Note Summarization",
    description:
      "Effortlessly distill lengthy notes into concise insights using advanced AI algorithms for enhanced productivity.",
    icon: "/features-note.svg",
  },
  {
    title: "Calendar-Integrated Action Items",
    description:
      "Seamlessly translate your to-dos into scheduled events by integrating action items with your calendar for efficient planning.",
    icon: "/features-calendar.svg",
  },
  {
    title: "Universal Tab Access",
    description:
      "Enjoy the convenience of accessing and managing your notes from any tab, providing unparalleled flexibility and ease of use.",
    icon: "/features-universe.svg",
  },
];

const Features = () => {
  return (
    <div
      id="features"
      className="w-[100%] p-3 justify-center flex flex-col items-center mt-20"
    >
      <h1 className="text-2xl md:text-4xl font-extrabold leading-relaxed w-[60%] lg:w-[30%] text-center">
        <span className="font-mono text-primary-600">Notes+AI</span> = get
        things done fast
      </h1>
      <div className="w-[100%] flex p-4 items-stretch justify-center flex-wrap gap-8">
        {featuresCardDetails.map((feature, index) => {
          return (
            <div
              key={feature.title}
              className="p-5 bg-primary-100 rounded-xl w-[300px] flex-grow hover:shadow-2xl transition-all"
            >
              <div className="w-[50px] h-[50px] bg-primary-900 rounded-full -mt-12 p-3">
                <h1 className="text-center font-extrabold text-white">
                  {index + 1}
                </h1>
              </div>
              <Image
                src={feature.icon}
                className="ml-[50%] -translate-x-[50%]"
                alt="logo"
                width={200}
                height={200}
                priority
              />
              <h1 className="font-extrabold text-lg mt-2">{feature.title}</h1>

              <p className="mt-5 font-extralight text-base">
                {feature.description}
              </p>
              <span className="font-extralight underline cursor-pointer text-xs">
                Learn more ↗️
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Features;
