"use client";
import Link from "next/link";
import { useState, useEffect } from "react";

const HeaderActionButton = (props: { classes: string }) => {
  const [isSignedUp, setIsSignedUp] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("authorization")) {
      setIsSignedUp(true);
    }
  }, []);

  const actionButton = (text: string, link: string, classes: string) => {
    return (
      <Link
        href={link}
        className={`px-5 py-2 rounded-2xl border hover:border-primary-800 bg-primary-900 hover:bg-transparent shadow-xl hover:shadow-none transition-all no-underline hover:text-primary-800 text-white ${classes}`}
      >
        {text}
      </Link>
    );
  };

  return (
    <>
      {isSignedUp
        ? actionButton("Open Notsly", "/user", props.classes)
        : actionButton("Sign Up", "/auth", props.classes)}
    </>
  );
};

export default HeaderActionButton;
