import Link from "next/link";
import Image from "next/image";

const Footer = () => {
  return (
    <footer
      className="rounded-tr-xl rounded-tl-xl 
    flex flex-col justify-between items-center flex-wrap 
    p-5 bg-primary-950 text-white gap-6"
    >
      <div className="flex gap-5 flex-wrap justify-center lg:justify-between w-[100%] items-center">
        <div className="flex flex-col gap-10">
          <div className="flex gap-5 items-center">
            <Link href="/" className="flex gap-2 items-center cursor-pointer">
              <Image
                src="/logo.svg"
                className="hover:scale-110"
                alt="logo"
                width={48 * 2}
                height={48 * 2}
                priority
              />
            </Link>
            <div>
              <h1 className="text-xl font-bold">Notsly</h1>
              <p className="text-sm font-light">AI NOTES SUMMARIZATION</p>
            </div>
          </div>
          <h1 className="text-white/80 hidden lg:block">
            © 2024 Notsly. All Rights Reserved.
          </h1>
        </div>
        <div className="flex gap-10 flex-wrap">
          <div className="flex flex-col gap-3">
            <h1 className="font-bold">Contact</h1>
            <div className="flex flex-col gap-2">
              <a
                href={"mailto:Support@notsly.com"}
                className="text-white/80 no-underline hover:text-white"
                target="_blank"
              >
                support@notsly.com
              </a>
              <a
                href={"https://twitter.com/NotslyAI"}
                className="text-white/80 no-underline hover:text-white"
                target="_blank"
              >
                Twitter
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="font-bold">Product</h1>
            <div className="flex flex-col gap-2">
              <a
                href={"https://notsly.productlogz.com/roadmap"}
                className="text-white/80 no-underline hover:text-white"
              >
                Roadmap
              </a>
              <a
                href={"https://notsly.productlogz.com/changelog"}
                className="text-white/80 no-underline hover:text-white"
              >
                Changelog
              </a>
              <a
                href={"https://notsly.productlogz.com/feature_requests"}
                className="text-white/80 no-underline hover:text-white"
              >
                Submit a Feature Request
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="font-bold">Company</h1>
            <div className="flex flex-col gap-2">
              <Link
                href={"/terms-and-conditions"}
                className="text-white/80 no-underline hover:text-white"
              >
                Terms and Conditions
              </Link>
              <Link
                href={"/privacy-policy"}
                className="text-white/80 no-underline hover:text-white"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="font-bold">Other</h1>
            <div className="flex flex-col gap-2">
              <Link
                href={"https://checkout.notsly.com/affiliates"}
                className="text-white/80 no-underline hover:text-white"
              >
                Become an Affiliate
              </Link>
              <Link
                href={"/blogs"}
                className="text-white/80 no-underline hover:text-white"
              >
                Blogs
              </Link>
              <Link
                href={"/sitemap.xml"}
                className="text-white/80 no-underline hover:text-white"
              >
                Sitemap
              </Link>
            </div>
          </div>
        </div>
        <h1 className="text-white/80 lg:hidden">
          © 2024 Notsly. All Rights Reserved.
        </h1>
      </div>
    </footer>
  );
};

export default Footer;
