export const headerLinks = [
  {
    title: "Features",
    href: "/#features",
    target: "_self",
  },
  {
    title: "Pricing",
    href: "/#pricing",
    target: "_self",
  },
  {
    title: "Youtube Summarizer",
    href: "/youtube-summarizer",
    target: "_self",
  },
  {
    title: "Blogs",
    href: "/blogs",
    target: "_self",
  },
  {
    title: "Become an Affiliate",
    href: "https://checkout.notsly.com/affiliates",
    target: "_blank",
  },
];
