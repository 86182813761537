"use client";
import { useEffect } from "react";

// Populate using values in copy-paste JavaScript snippet.
const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ["bam.nr-data.net"] },
  },
  info: {
    beacon: "bam.nr-data.net",
    errorBeacon: "bam.nr-data.net",
    licenseKey: process.env.NEXT_PUBLIC_NR_LICENSE_KEY,
    applicationID: process.env.NEXT_PUBLIC_NR_AGENT_OR_APP_ID,
    sa: 1,
  },
  loader_config: {
    accountID: "4349183",
    trustKey: "4349183",
    agentID: process.env.NEXT_PUBLIC_NR_AGENT_OR_APP_ID,
    licenseKey: process.env.NEXT_PUBLIC_NR_LICENSE_KEY,
    applicationID: process.env.NEXT_PUBLIC_NR_AGENT_OR_APP_ID,
  },
};

const Newrelic = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      (async () => {
        const { BrowserAgent } = await import(
          "@newrelic/browser-agent/loaders/browser-agent"
        );
        new BrowserAgent(options);
      })();
    }
  }, []);
  return <></>;
};

export default Newrelic;
