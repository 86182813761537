const Tooltip = (props: any) => {
  return (
    <div className="group relative inline-block">
      <span
        className="invisible group-hover:visible absolute top-12
      w-max ml-[50%] translate-x-[-50%]
      bg-slate-800 p-3 text-white rounded transition-all z-10
      "
      >
        {props.title}
      </span>
      {props.children}
    </div>
  );
};

export default Tooltip;
