import { WaitlistUsers } from ".";
import Link from "next/link";

interface Hero {
  showSmallMessage?: boolean;
  heading: React.ReactElement;
  showGradient?: boolean;
  subHeading?: string;
}

const Hero = (props: Hero) => {
  const getHeroContent = () => {
    return (
      <div className="w-[100%] flex flex-col items-center justify-center text-center text-balance z-1">
        {props.showSmallMessage && (
          <p className="font-bold text-xs opacity-80">AI NOTES SUMMARIZATION</p>
        )}
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold leading-relaxed w-[90%] lg:w-[40%]">
          {props.heading}
        </h1>
        <h2 className="text-xl mt-10 text-zinc-950 font-light w-[80%] lg:w-[28%] mb-5">
          {props.subHeading ||
            "Notsly uses AI to help you summarize notes and schedule them on your calendar."}
        </h2>
        <WaitlistUsers classes="items-center" textClass="text-center" />
        <Link
          href={"/auth"}
          className="px-5 py-2 rounded mt-5
              hover:text-white hover:bg-primary-800 transition-all
   flex gap-3 items-center justify-center bg-primary-900 text-white text-sm font-bold
   w-[80%] md:max-w-max"
        >
          Start Now
        </Link>
      </div>
    );
  };

  return (
    <div className="w-[100%] h-[100vh] overflow-y-auto overflow-x-hidden flex flex-col justify-center items-center flex-wrap relative left-0 right-0">
      {props.showGradient && (
        <div className="w-[100%] h-[100%] absolute -z-10 flex items-center justify-center">
          <div className="h-[100%] w-[100%] backdrop-blur-3xl bg-gradient-to-r from-primary-100 to-primary-500 blur-xl opacity-10"></div>
        </div>
      )}
      {getHeroContent()}
    </div>
  );
};

export default Hero;
