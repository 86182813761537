import Image from "next/image";
import Link from "next/link";
import { headerLinks } from "./headerContants";
import HeaderActionButton from "./HeaderActionButton";
import HeaderMenu from "./HeaderMenu";

const Header = () => {
  return (
    <nav
      className={
        "flex justify-between items-center w-[100%] px-10 py-3 fixed z-10 backdrop-blur-lg shadow"
      }
    >
      <Link href="/" className="flex gap-2 items-center cursor-pointer z-10">
        <Image
          src="/logo-full.svg"
          className="hover:scale-110 max-w-[96px] w-[96px] max-h-[48px] transition-all"
          alt="logo"
          width={48}
          height={48}
          priority
        />
      </Link>
      <div className="flex gap-10 items-center">
        {headerLinks.map((item) => (
          <Link
            key={item.href}
            className="text-zinc-500 hidden lg:block hover:text-zinc-800 no-underline"
            href={item.href}
            target={item.target}
          >
            {item.title}
          </Link>
        ))}
      </div>
      <HeaderActionButton classes="hidden lg:block" />
      <HeaderMenu />
    </nav>
  );
};

export default Header;
